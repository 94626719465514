<template>
<div style="margin-top:2%">
    <h1>De quelle longeur sont vos cheveux?</h1>
            <SelectButton v-model="length" :options="lengthOptions" optionLabel="name" @change="changeLength()" />
    <small v-show="validationErrors.length && submitted" class="p-error">Merci d'indiquer une longueur</small>

    <h3>{{lengthInfo}}</h3>

     <h1>Quelle épaisseur ont vos cheveux?</h1>
                 <SelectButton v-model="thickness" :options="thicknessOptions" optionLabel="name" />
            <small v-show="validationErrors.thickness && submitted" class="p-error">Merci d'indiquer une épaisseur</small>

  <div style="margin-top: 5%; margin-bottom: 30%;">
    <InputButton class = "navigation" label="Retour" @click="previousPage()" icon="pi pi-angle-left" iconPos="left" />
    <InputButton class = "navigation" label="Suivant" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
</div>

</div>
</template>

<style scoped>
    .navigation {
        margin-left:15px;
    }
</style>

<script setup>
import { defineEmits, ref, onMounted} from 'vue'   
import { useMainStore } from '../store'


const store = useMainStore();

const thickness = ref('');
const length = ref('');

const validationErrors = ref({});


 onMounted(() => {
    if(!store.clientData.firstname)
    {
        emit('go-home');
    }
});


const emit = defineEmits(['next-page', 'previous-page', 'go-home']);
function nextPage() {
    submitted.value = true;
    if(validateForm())
    {
        saveValues();
        emit('next-page', {pageIndex: 1});
    }
}

function previousPage() {
    emit('previous-page', {pageIndex: 1})
}

const lengthInfo = ref("");

var submitted = ref(false);


const lengthOptions = ref([
    {name: 'Très courts', value: 'xs'},
    {name: 'Courts', value: 's'},
    {name: 'Mi-longs', value: 'm'},
    {name: 'Longs', value: 'l'},
    {name: 'Très longs', value: 'xl'},
]);



const thicknessOptions = ref([
    {name: 'Fins', value: 'fin'},
    {name: 'Moyen', value: 'moyen'},
    {name: 'Epais', value: 'epais'},
    {name: 'Je ne sais pas', value: 'nsp'},
]);
 
function changeLength() {
            console.log(length.value.value)

    switch(length.value.value)
    {
        case 'xs':
            console.log('hey');
            lengthInfo.value = "J'ai le crâne rasé, ou presque !"
            break;
        case 's':
            lengthInfo.value = "Mes cheveux ne dépassent pas le menton"
            break;
        case 'm':
            lengthInfo.value = "Mes cheveux ne dépassent pas les épaules"
            break;
        case 'l':
            lengthInfo.value = "Mes cheveux ne dépassent pas le milieu du dos"
            break;
        case 'xl':
            lengthInfo.value = "Mes cheveux dépassent le milieu du dos !"
            break;
        default:
            lengthInfo.value = ""
            break;

    }

    console.log(lengthInfo.value)
}

function saveValues() {
    console.log(length.value.value)
    console.log(thickness.value.value)
    store.$patch((state) => {
        state.length = length.value.value,
        state.thickness = thickness.value.value
    })
}


function validateForm() {
            if (!thickness.value)
                validationErrors.value['thickness'] = true;
            else
                delete validationErrors.value['thickness'];

            if (!length.value)
                validationErrors.value['length'] = true;
            else
                delete validationErrors.value['length'];

            return !Object.keys(validationErrors.value).length;
}



</script>
