import { createApp } from 'vue'
import App from './App.vue'

import { createPinia } from 'pinia'



import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Steps from 'primevue/steps';
import SelectButton from 'primevue/selectbutton';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Fieldset from 'primevue/fieldset';
import Calendar from 'primevue/calendar';
import GridLoader from './components/GridLoader';
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


import { router } from "./router";



import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'    //icons

const app = createApp(App)


app.use(PrimeVue, {
    locale : {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Oui',
        reject: 'Non',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Annuler',
        dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
        monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
        monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
        today: "Aujourd'hui",
        weekHeader: 'Sm',
        firstDayOfWeek: 1,
        dateFormat: 'dd/mm/yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found',
        emptyMessage: 'No available options'
    }
});

app.use(ToastService);
app.use(router);
app.use(createPinia());


app.component('InputText', InputText);
app.component('InputButton', Button);
app.component('DisplayToast', Toast);
app.component('StepperView', Steps);
app.component('SelectButton', SelectButton);
app.component('CheckBox', Checkbox);
app.component('RadioButton', RadioButton);
app.component('FieldSet', Fieldset);
app.component('CalendarView', Calendar);
app.component('GridLoader', GridLoader);
app.component('DialogView', Dialog)
app.component('InputMask', InputMask);
app.component('VuePhoneNumberInput', VuePhoneNumberInput);




app.mount('#app')
