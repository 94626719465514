<template>
<div>
    <div class="time">
    <h3>Nous estimons qu'il faut prévoir environ</h3>
    <h2>{{writeMinutes(duration)}}</h2>
    <h3>Choisissez un rendez-vous : </h3>

    </div>
    <div class="loaderanderror">
    <GridLoader v-show="loading" :color="'#54f1d2'"/>

    <DialogView v-model:visible="error">
            <h1>Aïe...</h1>
            <p>Une erreur est survenue. Quelqu'un a peut-être réservé entre temps <br/> Veuillez réessayer ! En cas de problème, vous pouvez également me joindre par téléphone au <a href="tel:0782381360">078 238 13 60</a> </p>
                <InputButton label="Compris !" icon="pi pi-check" @click="closeAndRetry" autofocus />
    </DialogView>
    </div>

    <div class="nextApp" v-show="!loading && !error">

    <div class="threefirst"  v-for="(values, date) in days" :key="date">
      <FieldSet class="day" :legend="date">
          <InputButton @click="select(date, index)" class="p-button-success" :class="{'p-button-outlined': isNotSelected(date, index)}" v-for="(rdv, index) in values.appointments" :key="index">{{rdv}}</InputButton>
          <p v-if ="values.appointments.length==0"><i>Il n'y a pas de rendez-vous disponible</i></p>
    </FieldSet>
    </div>
     <div class="datepicker field col-12 md:col-4">
        <label for="icon">Choisir une autre date</label>
        <CalendarView id="icon" :minDate="mindate" :maxDate="maxdate" v-model="dateViewed" :="true" :manualInput="false" :showIcon="true" :touchUI="true" @date-select="updateApps()"/>
    </div>

    </div>
    
  <div style="margin-top: 5%; margin-bottom: 30%;">
     <InputButton class="button" label="Retour" @click="previousPage()" icon="pi pi-angle-left" iconPos="left" />
     <InputButton
      class="navigation p-button-success"
      label="Réserver"
      @click="book()"
      icon="pi pi-check-circle"
      iconPos="right"
      v-show="selectedDate != ''"
    />
    </div>
</div>


</template>



<style>
.datepicker {
    margin-bottom:3%;
}

.datepicker label {
    margin-right:10px;
}

.threefirst {
    text-align: left;
}

.threefirst button {
    margin-right:8px;
    width:75px;
}


.threefirst .day {
    margin-bottom:10px;
}



</style>


<script setup>
import { ref, defineEmits, onMounted} from 'vue'

const emit = defineEmits(['previous-page', 'go-home', 'go-confirm']);

import { storeToRefs } from 'pinia'
import { useMainStore } from '../store'

import {getFrom, getTo, getTime } from  '../dateUtils.js'

import axios from 'axios'

const store = useMainStore();

var mindate = new Date();
var maxdate = new Date();
maxdate.setMonth(maxdate.getMonth() + 6);


const { duration, days, loading  } = storeToRefs(useMainStore())

 onMounted(() => {
    if(!(store.duration>0))
    {
        emit('go-home');
    }
});

const dateViewed = ref(new Date());

const selectedDate = ref("");

const error = ref(false);


var selected = ref(0);


function previousPage() {
    emit('previous-page', {pageIndex: 3})
}

function writeMinutes(min) {
    var hours =  Math.floor(min/60);
    var minutes = min % 60;

    if(hours > 0 && minutes < 10)
    {
        return `${hours}h0${minutes}`
    }
    if(hours > 0)
    {
        return `${hours}h${minutes}`
    }
    return `${minutes} minutes`
}



function isNotSelected(date, id) {
    return selected.value != date + '/' + id;
}

function select(date, id) {
    console.log('selecting ' + date + ' ' +id);
    selected.value = date + '/' + id;
    selectedDate.value = store.days[date]["datestring"][0] + getTime(store.days[date]["appointments"][id]);

     store.$patch((state) => {
              state.datestring = date;
              state.timestring = store.days[date]["appointments"][id];
          });

    
}

function updateApps() {

    const passDate = new Date(dateViewed.value.getTime());
    
     store.$patch((state) => {
        state.loading = true;
        state.days = {};
      });

   axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments?from=${getFrom(passDate)}&to=${getTo(passDate)}&duration=${store.duration}`)
      .then(response => {
          store.$patch((state) => {
            state.days = response.data;
            state.loading = false;
      });
      })
}


const lengthLookup = ref({
    'xs' : 'Très courts',
    's' : 'Courts',
    'm' : 'Mi-longs',
    'l': 'Longs', 
    'xl': 'Très longs'
}
);



const thicknessLookup = ref(
    {
    'fin' : 'Fins',
    'moyen' : 'Moyen', 
    'epais' : 'Epais', 
    'nsp' :  'Je ne sais pas'}
);
 

function book() {
    var clientData = store.clientData;
    clientData.length = lengthLookup.value[store.length];
    clientData.thickness = thicknessLookup.value[store.thickness];
    clientData.services = store.services;

    var json = JSON.stringify(clientData);

    const options = {
    headers: {"content-type": "application/json"}
}
        console.log(days)


        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments?appointmentTime=${selectedDate.value}&duration=${store.duration}`, json, options)
      .then(response => {
          console.log(response.status)
          emit('go-confirm');
      })
      .catch(reason => {
          console.log(reason);
          this.error = true;
      });
}

function closeAndRetry() {
    error.value = false;
    updateApps();
}

</script>
