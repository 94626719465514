import { createRouter, createWebHistory } from 'vue-router';
import HairSelection from './components/HairSelection.vue';
import ServicesSelection from './components/ServicesSelection.vue';
import WelcomePage from './components/WelcomePage.vue'
import Appointment from './components/Appointment.vue'
import Confirmation from './components/Confirmation.vue'

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
        path: '/',
        redirect: '/home'
        },
        {
        path: "/home",
        component: WelcomePage
    },{
        path: "/hair",
        component: HairSelection
    },{
        path: "/services",
        component: ServicesSelection
    },
    {
        path: "/rdv",
        component: Appointment
    }, 
    {
        path: "/merci",
        component: Confirmation
    }
    ]
});
