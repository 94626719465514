<template>
<div>
  
  <div class="card">
    <StepperView :model="items"/>
  </div>

    <router-view v-slot="{Component}" @previous-page="previousPage($event)" @next-page="nextPage($event)" @go-home="goHome()" @go-confirm="goConfirm()">
      <keep-alive>
                <component :is="Component" />
        </keep-alive>
    </router-view>
    
</div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';


export default({
  setup() {
    const router = useRouter();

    const items = ref([
      {
        label:'Contact',
        to: '/home'
      },
      {
        label: 'Cheveux',
        to: '/hair'
      },
      {
         label: 'Services',
        to: '/services'
      },
      {
        label: 'Rendez-vous',
        to: '/rdv'
      }
    ]);



    const nextPage = (event) => {
      router.push(items.value[event.pageIndex + 1].to);
    };

    const previousPage = (event) => {
        router.push(items.value[event.pageIndex - 1].to);
    };

    const goHome = () => {
      router.push(items.value[0].to);
    }

    const goConfirm = () => {
      router.push('/merci');
    }




    return { items, nextPage, previousPage, goHome, goConfirm };
  }
});




</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
