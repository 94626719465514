<template>
<div>
    <h1>Merci !</h1>
    <img src="../assets/iroquoise_logo_rvb.png" style = "width:200px"/>
    <h2>Votre rendez-vous est confirmé</h2>
    <h3> Le {{ store.datestring }} à {{ store.timestring }} à l'Iroquoise</h3>

    <h4>Chemin du couchant 16</h4>
    <h4>1752 Villars-sur-Glâne</h4>
          
      <FieldSet legend="Information">
    <p>
      Vous allez rececvoir une <b>confirmation par SMS dans les minutes à venir.</b> Si vous ne recevez pas de confirmation, ou que vous souhaitez annuler ou déplacer votre rendez-vous, ou pour toute question, contactez-moi au <a href="tel:0782381360">078 238 13 60</a>
    </p>
  </FieldSet>


</div>
</template>

<style scoped>
   
</style>

<script setup>


import { useMainStore } from '../store'

import { defineEmits, onMounted } from "vue";

const emit = defineEmits(['next-page', 'previous-page', 'go-home', 'go-confirm']);




const store = useMainStore();



 onMounted(() => {
    if(!store.length)
    {
        emit('go-home');
    }
});





</script>
