<template>
  <div>
    <h1>Que souhaitez-vous faire?</h1>
    <div style="margin-left: 40%; text-align: left">
      <div class="field-checkbox">
        <CheckBox
          id="coupe"
          name="coupe"
          value="coupe"
          v-model="services"
          @change="validate()"
        />
        <label for="coupe">Coupe</label>

        <div
          v-show="services.includes('coupe') && length != 'xs' && length != 's'"
        >
          <div class="field-radiobutton">
            <RadioButton
              id="coupeComplete"
              name="coupeComplete"
              value="coupeComplete"
              v-model="coupeDetails"
            />
            <label for="coupeComplete">Complète</label>
          </div>

          <div class="field-radiobutton">
            <RadioButton
              id="pointes"
              name="pointes"
              value="pointes"
              v-model="coupeDetails"
            />
            <label for="pointes">Pointes uniquement (sans dégradé)</label>
          </div>
        </div>
      </div>

      <div class="field-checkbox">
        <CheckBox
          id="brushing"
          name="brushing"
          value="brushing"
          v-model="services"
          @change="validate()"
        />
        <label for="coiffure">Séchage / Mise en forme</label>
      </div>
      <div class="field-checkbox" v-show="!couleurDisabled">
        <CheckBox
          id="couleur"
          name="couleur"
          value="couleur"
          v-model="services"
          @change="validate()"
        />
        <label for="couleur">Couleur</label>
        <div v-show="services.includes('couleur')">
          <div class="field-radiobutton">
            <RadioButton
              id="couleurComplete"
              name="couleurComplete"
              value="couleurComplete"
              v-model="couleurDetails"
            />
            <label for="couleurComplete">Complète</label>
          </div>

          <div class="field-radiobutton">
            <RadioButton
              id="racines"
              name="racines"
              value="racines"
              v-model="couleurDetails"
            />
            <label for="racines">Racines uniquement</label>
          </div>
        </div>
      </div>
      <div class="field-checkbox" v-show="!mechesDisabled">
        <CheckBox
          id="mèches"
          name="mèches"
          value="mèches"
          v-model="services"
          @change="validate()"
        />
        <label for="mèches">Mèches</label>
        <div v-show="services.includes('mèches')">
          <div class="field-radiobutton">
            <RadioButton
              id="mecheComplete"
              name="mecheComplete"
              value="mecheComplete"
              v-model="mecheDetails"
            />
            <label for="mecheComplete">Tête complète</label>
          </div>

          <div class="field-radiobutton">
            <RadioButton
              id="partielles"
              name="partielles"
              value="partielles"
              v-model="mecheDetails"
            />
            <label for="partielles">Mèches parsemées</label>
          </div>
        </div>
      </div>
      <div class="field-checkbox" v-if="length != 'xs'" v-show="!lissageDisabled">
        <CheckBox
          id="lissage"
          name="lissage"
          value="lissage"
          v-model="services"
          v-if="length != 'xs'"
          @change="validate()"
        />
        <label for="lissage">Lissage</label>
      </div>
      <div class="field-checkbox" v-if="length != 'xs'" v-show="!permanenteDisabled">
        <CheckBox
          
          id="permanente"
          name="permanente"
          value="permanente"
          v-model="services"
          @change="validate()"
        />
        <label for="permanente">Permanente</label>
      </div>
      <div class="field-checkbox">
        <CheckBox
          id="barbe"
          name="barbe"
          value="barbe"
          v-model="services"
          @change="validate()"
        />
        <label for="barbe">Barbe</label>
      </div>
    </div>
    <small v-show="validationErrors.services && submitted" class="p-error">Merci de choisir au moins un service</small>

  </div>

  <FieldSet legend="Information">
    <p>
      Si les services que vous souhaitez ne sont pas dans cette liste, que vous
      avez des questions ou besoin de conseils, ou en cas de doute, vous pouvez
      prendre rendez-vous par téléphone au <a href="tel:0782381360">078 238 13 60</a>
    </p>
  </FieldSet>

  <div style="margin-top: 5%; margin-bottom: 30%;">
    <InputButton
      class="navigation"
      label="Retour"
      @click="previousPage()"
      icon="pi pi-angle-left"
      iconPos="left"
    />
    <InputButton
      class="navigation"
      label="Suivant"
      @click="nextPage()"
      icon="pi pi-angle-right"
      iconPos="right"
    />
  </div>
</template>

<style>
FieldSet {
  margin-left: 10%;
  margin-right: 10%;
}

.field-checkbox {
  margin-bottom: 20px;
}

.field-checkbox label {
  margin-left: 5px;
}

.field-radiobutton {
  margin-top: 10px;
  margin-left: 40px;
}

.navigation {
        margin-left:15px !important;
        }

</style>

<script setup>
import { defineEmits, ref, onMounted } from "vue";

import { storeToRefs } from 'pinia'
import { useMainStore } from '../store'
import {getFrom, getTo} from  '../dateUtils.js'

import axios from 'axios'

const store = useMainStore();
const { length  } = storeToRefs(useMainStore())

const validationErrors = ref({});

var submitted = ref(false)

 onMounted(() => {
    if(!store.length)
    {
        emit('go-home');
    }
});



const emit = defineEmits(['next-page', 'previous-page', 'go-home', 'go-confirm']);

function nextPage() {
  submitted.value = true;
    if(validateForm())
    {
 computeTime();
  store.$patch((state) => {
        state.days = {};
        state.loading = true;
        state.services = services.value;
      });

      console.log(`${process.env.VUE_APP_API_URL}/Appointments?from=${getFrom(new Date())}&to=${getTo(new Date())}&duration=${store.duration}`)
   axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments?from=${getFrom(new Date())}&to=${getTo(new Date())}&duration=${store.duration}`)
      .then(response => {
          store.$patch((state) => {
        state.days = response.data;
        state.loading = false;
      });
      })
  emit("next-page", { pageIndex: 2 });
    }
} 

function previousPage() {
  emit("previous-page", { pageIndex: 2 });
}




const services = ref([]);

const couleurDisabled = ref(false);
const mechesDisabled = ref(false);
const lissageDisabled = ref(false);
const permanenteDisabled = ref(false);

const coupeDetails = ref("coupeComplete");
const couleurDetails = ref("couleurComplete");
const mecheDetails = ref("mecheComplete");


function validate() {
  enableAll();
  if (
    services.value.includes("coupe") ||
    services.value.includes("lissage") ||
    services.value.includes("permanente") ||
    services.value.includes("couleur") ||
    services.value.includes("mèches")
  ) {
    services.value.push("brushing");
  }
  if (services.value.includes("permanente")) {
    lissageDisabled.value = true;
    couleurDisabled.value = true;
    mechesDisabled.value = true;
  }

  if (services.value.includes("lissage")) {
    permanenteDisabled.value = true;
    couleurDisabled.value = true;
    mechesDisabled.value = true;
  }

  if (services.value.includes("couleur")) {
    permanenteDisabled.value = true;
    lissageDisabled.value = true;
  }

  if (services.value.includes("mèches")) {
    permanenteDisabled.value = true;
    lissageDisabled.value = true;
  }

}

function enableAll() {
  couleurDisabled.value = false;
  mechesDisabled.value = false;
  lissageDisabled.value = false;
  permanenteDisabled.value = false;
}

const baseTimes = {
  xs: {
    brushing: 15,
    barbe: 30,
    coupe: 30,
    couleur: 60,
    mèches: 75,
    lissage: 0,
    permanente: 0,
  },
  s: {
    brushing: 20,
    barbe: 30,
    coupe: 45,
    couleur: 60,
    mèches: 90,
    lissage: 120,
    permanente: 75,
  },
  m: {
    brushing: 30,
    barbe: 30,
    coupe: 45,
    couleur: 60,
    mèches: 120,
    lissage: 160,
    permanente: 75,
  },
  l: {
    brushing: 45,
    barbe: 30,
    coupe: 60,
    couleur: 75,
    mèches: 140,
    lissage: 180,
    permanente: 90,
  },
  xl: {
    brushing: 60,
    barbe: 30,
    coupe: 75,
    couleur: 75,
    mèches: 160,
    lissage: 180,
    permanente: 120,
  },
};

const removeMechesPartielles = {
  xs: {
    withoutCoupeCouleur: 30,
    withCoupeCouleur: 45,
  },
    s: {
    withoutCoupeCouleur: 20,
    withCoupeCouleur: 45,
  },
    m: {
    withoutCoupeCouleur: 40,
    withCoupeCouleur: 75,
  },
    l: {
    withoutCoupeCouleur:40 ,
    withCoupeCouleur: 95,
  },
    xl: {
    withoutCoupeCouleur: 40,
    withCoupeCouleur: 115,
  },
}

const removeCouleurRacines = {
  xs: {
    remove: 0,
  },
    s: {
    remove: 0,
  },
    m: {
    remove: 0,
  },
    l: {
    remove:15 ,
  },
    xl: {
    remove: 15,
  },
}


function computeTime() {
  console.log(services);
  var time = 0;
    console.log(time);


  //rule 1 : additioner les temps sauf pour le brushing
  services.value.filter(s => s != "brushing").forEach((service) => {
    console.log("service " + service + " finalength " + store.finalLength);
    time = time + baseTimes[store.finalLength][service];
    console.log("basic time : " + time);
  });

  //rule 2 : si il n'y a pas dde couple complète et pas de permanente, ajouter le brushing, sauf si c'est une babe
if (
    (!services.value.includes("coupe") || coupeDetails.value == "pointes") &&
    !services.value.includes("permanente") && 
    !(services.value.length == 1 && services.value.includes("barbe"))
  ) 
  {
    time =  time + baseTimes[store.finalLength]['brushing'];
  }

  //rule 3  : si il y a une permanente mais pas de coupe, ajouter 30 min de séchage
  if (
    (!services.value.includes("coupe") || coupeDetails.value == "pointes") &&
    services.value.includes("permanente")
  ) {
    time = time + 30;
    console.log("added permanente séchange : " + time);
  }

//rule 4 : si les mèches sont partielles alors on enlève un temps variable (tableau), mais si il y a une couleur et une coupe avec, 
//on enlève un temps pour arriver à 30 min de mèches
console.log("mèches : " + mecheDetails.value)
if(
  services.value.includes("mèches") && mecheDetails.value == "partielles" && !(services.value.includes("couleur")&&services.value.includes("coupe"))
)
{
  time = time - removeMechesPartielles[store.finalLength]['withoutCoupeCouleur'];
  console.log("remove time without");
}

if(
  services.value.includes("mèches") && mecheDetails.value == "partielles" && (services.value.includes("couleur")&&services.value.includes("coupe"))
)
{
  time = time - removeMechesPartielles[store.finalLength]['withCoupeCouleur'];
    console.log("remove time with");

}

//rule 5 : si la couleur est racine uniquement, enlever du temps
if(services.value.includes("couleur") && couleurDetails.value == "racines")
{
  time = time - removeCouleurRacines[store.finalLength]['remove'];
}


//arrondi aux 15 mins les plus proches
  time = Math.round(time / 15) * 15;

  store.$patch((state) => {
      state.duration = time;
  });
}

function validateForm() {
            if (services.value.length == 0)
                validationErrors.value['services'] = true;
            else
                delete validationErrors.value['services'];

            return !Object.keys(validationErrors.value).length;
}


</script>