<template>
<div>
    <h1>Bienvenue</h1>
                <!-- information block-->
    <div v-if="config && config.showInfoBlock" class="info-block">
        <h2>Information</h2>
        <p v-if="config.infoBlockText">{{ config.infoBlockText }}</p>
    </div>


             <div class="p-fluid clientdata">
                    <div class="field">
                        <label for="firstname">Prénom</label>
                        <InputText id="firstname" v-model="firstname" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.firstname && submitted}" />
                        <small v-show="validationErrors.firstname && submitted" class="p-error">Merci d'indiquer votre prénom</small>
                    </div>
                    <div class="field">
                        <label for="lastname">Nom</label>
                        <InputText id="lastname" v-model="lastname" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.lastname && submitted}" />
                        <small v-show="validationErrors.lastname && submitted" class="p-error">Merci d'indiquer votre nom de famille.</small>
                    </div>
                    <div class="field">
                        <label for="phone">Numéro de téléphone</label>
                        <InputText id="phone" v-model="phone" @keyup.enter="nextPage()" />
                        <small v-show="validationErrors.phone && submitted" class="p-error">Veuillez entrer un numéro de téléphone suisse valide au format 079/999.99.99 ou 0799999999</small>

                    </div>
                </div>
                <p>Les informations collectées sur ce site sont stockées et exclusivement utilisées par l'iroquoise pour le traitement des rendez-vous. Elles ne sont jamais transmises à des tiers.</p>

                </div>
<div style="margin-top: 5%; margin-bottom: 30%;">
    <InputButton label="Suivant" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />

</div>
</template>

<style scoped>
    .clientdata {
        width:75%;
        margin-left:auto;
        margin-right:auto;
        margin-bottom:5%;
    }

    .info-block {
        background-color: #f9f9f9;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 5px;
    }
</style>

<script setup>
import { defineEmits, ref, onMounted } from 'vue'

const emit = defineEmits(['next-page', 'previous-page', 'go-home', 'go-confirm']);

const firstname = ref("");
const lastname = ref("");
const phone = ref("");

const validationErrors = ref({});


var submitted = ref(false)

import { useMainStore } from '../store'
import axios from 'axios'



const store = useMainStore();

const config = ref(null)

onMounted(async () => {
  try {
    const response = await axios.get('https://rdv.iroquoise.ch/config.json')
    config.value = response.data
  } catch (error) {
    console.error('Failed to fetch config:', error)
  }
})


function nextPage() {
    submitted.value = true;
    if(validateForm())
    {

        phone.value = phone.value.replace(/ /g, '').replace(/\//g, '').replace(/\./g, '');
        console.log("phone : " + phone.value);
        store.$patch((state) => {
            state.clientData = {firstname : firstname, lastname: lastname, phone : phone}
        });
          axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/wakeup`)
      .then(response => {
          console.log("woke up API : " + response.data);
      });
      
    emit('next-page', {pageIndex: 0});
    }
}

function validateForm() {
            if (!firstname.value.trim())
                validationErrors.value['firstname'] = true;
            else
                delete validationErrors.value['firstname'];

            if (!lastname.value.trim())
                validationErrors.value['lastname'] = true;
            else
                delete validationErrors.value['lastname'];


            var form1 = /^\d{10}$/; // 0774476279
            var form2 = /^\d{3}\/\d{3}\.\d{2}\.\d{2}$/;
            var form3 = /^\d{3}\s\d{3}\s\d{2}\s\d{2}$/;
  

            if (!phone.value.trim().match(form1) && !phone.value.trim().match(form2) && !phone.value.trim().match(form3))
            {
                validationErrors.value['phone'] = true;
            }
            else 
            {
                delete validationErrors.value['phone'];
            }

            return !Object.keys(validationErrors.value).length;
}


</script>
