import { defineStore } from 'pinia'


const lengthLookup = {  "xs" : {"fin" : "xs", "moyen" : "xs", "epais": "s", "nsp":"xs"},
                        "s" : {"fin" : "s", "moyen" : "s", "epais": "m", "nsp":"m"},
                        "m" : {"fin" : "s", "moyen" : "m", "epais": "l", "nsp":"l"},
                        "l" : {"fin" : "m", "moyen" : "l", "epais": "xl", "nsp":"l"},
                        "xl" : {"fin" : "m", "moyen" : "l", "epais": "xl", "nsp":"xl"} };


export const useMainStore = defineStore({
  id: 'main',
  state: () => ({
    length : '', 
    thickness: '',
    duration: 0,
    services: [], 
    days : {},
    clientData : {},
    loading : false,
    datestring: '',
    timestring: ''
  }),
  getters: {
    finalLength: (state) => {
      return lengthLookup[state.length][state.thickness];
    }
    
    
  }
});