
function getMondayFromDate(date) {
    const first = date.getDate() - date.getDay() + 1;
  
    const monday = new Date(date.setDate(first));
    return monday;
  }
  
  
  function getSaturdayFromDate(date) {
    const first = date.getDate() - date.getDay() + 6;
  
    const sat = new Date(date.setDate(first));
    return sat;
  }



  function getFrom(date) {
    const monday = getMondayFromDate(date);
    return `${monday.getFullYear()}-${("0" + (monday.getMonth() + 1)).slice(-2)}-${("0" + monday.getDate()).slice(-2)}`;
 }
 
 function getTo(date) {
   const sat = getSaturdayFromDate(date);
    return `${sat.getFullYear()}-${("0" + (sat.getMonth() + 1)).slice(-2)}-${("0" + sat.getDate()).slice(-2)}`
 }

 function getTime(time) {
    return "T"+time+":00"
 }

 function getDate(date) {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
 }
 
 
 
module.exports =  {getFrom, getTo, getTime, getDate};

 